import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import CommercialProperty from './CommercialProperty'

import Container from '../../container'
import SideBar from '../../SideBar'
import Footer from '../../Footer'
import styles from '../page.module.css'

const Page = ({title, _rawBody, teaserImage, location, squareFootage, saleOrLease, realEstatePhone, realEstateEmail, realEstateAgent, realEstateAgency, price, propertyType}) => {
  const data = useStaticQuery(graphql`
    {
    sanitySideBar(id: {eq: "1e61ad03-609d-5cae-a4d3-468279fe42c2"}) {
      title
      links {
        _key
        title
        siteLink
      }
    }
  }
  `)

  return (
    <>

      <article className={styles.root}>

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div>
            <div className={styles.mainContent}>

              <div className={styles.innerMainContent}>
                <CommercialProperty _rawBody={_rawBody} teaserImage={teaserImage} location={location} squareFootage={squareFootage} saleOrLease={saleOrLease} realEstatePhone={realEstatePhone} realEstateEmail={realEstateEmail} realEstateAgent={realEstateAgent} realEstateAgency={realEstateAgency} propertyType={propertyType} price={price} />
              </div>

              {data.sanitySideBar && (<SideBar data={data.sanitySideBar} />)}

            </div>
          </div>
        </Container>
      </article>

      <Footer topBorder />

    </>
  )
}
export default Page
