import React from 'react'
import Image from 'gatsby-image'
import {Link} from 'gatsby'
import LinkIcon from '../../../lib/LinkIcon'
import PortableText from '../../serializers/portableText'
import styles from './commercialproperty.module.css'

export default function SpecialEvent ({title, _rawBody, teaserImage, location, squareFootage, saleOrLease, realEstatePhone, realEstateEmail, realEstateAgent, realEstateAgency, price, propertyType}) {
  return (
    <>
      <div className={styles.propertyWrapper}>

        <div className={styles.detailsWrapper}>

          <div className={styles.specs}>
            {saleOrLease && (<p><span className={styles.label}>Sale or Lease:</span> {saleOrLease}</p>)}
            {squareFootage && (<p><span className={styles.label}>Square footage:</span> {squareFootage}</p>)}
            {price && (<p><span className={styles.label}>Price:</span> {price}</p>)}
          </div>

          {_rawBody
            ? <PortableText blocks={_rawBody} />
            : <div className={styles.claim}>

              <h4>Is this your business?</h4>
              <p>
                Claim your listing on the Greeley DDA site by sending a brief write-up and logo through our <Link to={`/contact/`} className={styles.link}>Claims Form</Link>!
              </p>

            </div>

          }

        </div>

        <div className={styles.sideWrapper}>
          {teaserImage && teaserImage.asset && <Image fluid={teaserImage.asset.fluid} alt={title} />}
          <div className={styles.address}>
            <p>{location.streetAddress} <br />
              {location.city}, {location.state} {location.zipcode}</p>

            <div className={styles.linkWrapper}><LinkIcon type='map' /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(location.streetAddress)},${encodeURI(location.city)},${encodeURI(location.state)}`} target='_blank' rel='noopener noreferrer'title={`Map and Directions to ${title}`}className={styles.mapLink}>map &amp; directions</a></div>

          </div>

          {(realEstateAgency || realEstateAgent || realEstatePhone || realEstateEmail) && (
            <div className={styles.agent}>

              <p>{realEstateAgency && (<><strong>{realEstateAgency}</strong><br /></>)}
                {realEstateAgent && (<><i>{realEstateAgent}</i><br /></>)}</p>
              {realEstatePhone && <div className={styles.linkWrapper}><LinkIcon type='call' /><a href={`tel:${realEstatePhone}`} target='_blank' rel='noopener noreferrer' className={styles.callLink}>{realEstatePhone}</a></div>}
              {realEstateEmail && <div className={styles.linkWrapper}><LinkIcon type='email' /> <a href={`mailto:${realEstateEmail}`} target='_blank' rel='noopener noreferrer' className={styles.emailLink}>{realEstateEmail}</a></div>}

            </div>
          )}
        </div>

      </div>
    </>
  )
}
